import React, { useEffect, useState } from "react";
import { numberToMonth, monthToNumber } from "../monthConstants";
import "antd/dist/antd.css";

import { Radio } from "antd";
import { Funnel } from "funnel-react";
import "react-funnel-pipeline/dist/index.css";

// MIKE: LEFT OFF STARTING TO THINK ABOUT HOW TO GET SUBSCRIBERS PER MONTH

const Home = () => {
  const [monthsAvailable, setMonthsAvailable] = useState(null);
  const [stats, setStats] = useState(null);
  const [monthStats, setMonthStats] = useState(null);
  const [funnelData, setFunnelData] = useState(null);
  const [monthSubscribers, setMonthSubscribers] = useState(null);

  useEffect(async () => {
    const getMonthOptionsRequest = await fetch(
      "https://still-inlet-47511.herokuapp.com/month-options",
      {
        method: "GET",
      }
    );

    const getMonthOptionsResponse = await getMonthOptionsRequest.json();
    console.log(getMonthOptionsResponse.monthOptions);
    let monthOptionNumbers = getMonthOptionsResponse.monthOptions;
    monthOptionNumbers.forEach((monthNumber, index) => {
      monthOptionNumbers[index] = numberToMonth[monthNumber];
    });
    console.log(monthOptionNumbers);
    setMonthsAvailable(monthOptionNumbers);

    const getStatsRequest = await fetch(
      `https://still-inlet-47511.herokuapp.com/get-stats`,
      {
        method: "GET",
      }
    );
    const getStatsResponse = await getStatsRequest.json();
    console.log(getStatsResponse);

    const getSubscribersRequest = await fetch(
      `https://extension-backend.herokuapp.com/subscriptions?obfu=scated`,
      {
        method: "GET",
      }
    );
    const getSubscribersResponse = await getSubscribersRequest.json();
    console.log(getSubscribersResponse);

    getStatsResponse.subscribersList = getSubscribersResponse;

    setStats(getStatsResponse);
  }, []);

  const changeMonth = (e) => {
    const monthNumber = monthToNumber[e.target.value];

    let monthStats = {
      totalImpressions: 0,
      totalInstalls: 0,
      totalUninstalls: 0,
      monthNumber: monthNumber,
    };
    // impressions
    for (const [date, count] of Object.entries(stats.impressions)) {
      var d = new Date(date); //converts the string into date object
      var impressionMonthNumber = d.getMonth();
      if (impressionMonthNumber === monthNumber) {
        monthStats.totalImpressions += parseInt(count);
      }
    }

    // installs
    for (const [date, count] of Object.entries(stats.installs)) {
      var d = new Date(date); //converts the string into date object
      var installMonthNumber = d.getMonth();
      if (installMonthNumber === monthNumber) {
        monthStats.totalInstalls += parseInt(count);
      }
    }

    // uninstalls
    for (const [date, count] of Object.entries(stats.uninstalls)) {
      var d = new Date(date); //converts the string into date object
      var uninstallMonthNumber = d.getMonth();
      if (uninstallMonthNumber === monthNumber) {
        monthStats.totalUninstalls += parseInt(count);
      }
    }

    // weekly users
    // we should just move this to stats since we don't need to calculate for each month
    let currentMonthNumber = new Date(
      Object.entries(stats.weeklyUsers)[0][0]
    ).getMonth();
    let currentMonthCount = Object.entries(stats.weeklyUsers)[0][1];
    console.log("blue", currentMonthNumber, currentMonthCount);
    let weeklyUsersPerMonth = {};
    for (const [date, count] of Object.entries(stats.weeklyUsers)) {
      const modifiedDate = date.replace("-", "/");
      var d = new Date(modifiedDate); //converts the string into date object
      var weeklyUserMonthNumber = d.getMonth();

      if (weeklyUserMonthNumber != currentMonthNumber) {
        weeklyUsersPerMonth[currentMonthNumber] = currentMonthCount;
        currentMonthNumber += 1;
      }
      currentMonthCount = count;
    }
    monthStats.weeklyUsersPerMonth = weeklyUsersPerMonth;

    // new subscribers per month
    let subscribersForCurrentMonth = 0;
    stats.subscribersList.forEach((subscriber) => {
      const milliseconds = subscriber.createdAt * 1000;
      const createdDate = new Date(milliseconds);
      if (createdDate.getMonth() === monthNumber) {
        subscribersForCurrentMonth += 1;
      }
    });
    monthStats.newSubscribers = subscribersForCurrentMonth;

    setMonthStats(monthStats);
    console.log("setting funnel data");
    setFunnelData([
      { label: "Impressions", quantity: monthStats.totalImpressions },
      { label: "Installs", quantity: monthStats.totalInstalls },
      {
        label: "Installs after uninstalls",
        quantity: monthStats.totalInstalls - monthStats.totalUninstalls,
      },
      { label: "Upgrades", quantity: monthStats.newSubscribers },
    ]);
    console.log(monthStats);
  };

  return (
    <div className="container d-flex align-items-center flex-column pt-5">
      {monthsAvailable && stats ? (
        <Radio.Group onChange={changeMonth}>
          {monthsAvailable.map((month) => {
            return (
              <Radio.Button value={month} key={month}>
                {month}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      ) : (
        <div className="d-flex align-items-center flex-column pt-5">
          Loading
        </div>
      )}
      {monthStats && (
        <div className="container d-flex align-items-center flex-column">
          <div>Total impressions: {monthStats.totalImpressions} (100%)</div>
          <div>
            Total installs: {monthStats.totalInstalls} (We retain{" "}
            {(
              (monthStats.totalInstalls / monthStats.totalImpressions) *
              100
            ).toFixed(2)}
            %)
          </div>

          <div>
            Installs after uninstalls:{" "}
            {monthStats.totalInstalls - monthStats.totalUninstalls} (We retain{" "}
            {(
              ((monthStats.totalInstalls - monthStats.totalUninstalls) /
                monthStats.totalInstalls) *
              100
            ).toFixed(2)}
            %)
          </div>
          <div>
            Conversions: {monthStats.newSubscribers} (We retain{" "}
            {(
              (monthStats.newSubscribers /
                (monthStats.totalInstalls - monthStats.totalUninstalls)) *
              100
            ).toFixed(2)}
            %)
          </div>
          <br />
          <div>
            Weekly Users:{" "}
            {monthStats.weeklyUsersPerMonth[monthStats.monthNumber]}
          </div>
          <div>Total Uninstalls: {monthStats.totalUninstalls}</div>
          {monthStats && (
            <Funnel
              height={252}
              colors={{
                graph: ["#1890FF", "#BAE7FF"],
                percent: "red",
                label: "yellow",
                value: "orange",
              }}
              valueKey="quantity"
              width={800}
              data={funnelData}
              displayPercent={true}
              //   showRunningTotal={true}
            />
          )}
          {/* <div>
            Uninstalls over weekly users:{" "}
            {(
              (monthStats.totalUninstalls /
                monthStats.weeklyUsersPerMonth[monthStats.monthNumber]) *
              100
            ).toFixed(2)}
            %
          </div> */}
        </div>
      )}
    </div>
  );
};
export default Home;
function onChange(e) {
  console.log(`radio checked:${e.target.value}`);
}
